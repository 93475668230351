@import "theme-variables";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  font-family: Monoserrat, sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  line-height: $default-line-height;
  font-size: $default-font-size;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.send-icon {
  transform: rotate(315deg);
}

.mat-input-and-select {
  height: 3.25em;
  margin-bottom: 1.5em;
  width: 100%;
  position: relative;
}

/* stylelint-disable selector-max-specificity,selector-max-compound-selectors */

.mat-input-and-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  font-size: 0.75em;
  line-height: 1.125em;
  font-weight: 700;
  color: $dark-color;
}

/* stylelint-enable */

.mat-input-and-select .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  overflow-x: clip;
}

.mat-input-and-select .mat-mdc-form-field-subscript-wrapper {
  position: absolute;
  top: 100%;
}

.mat-input-and-select .mat-mdc-text-field-wrapper {
  background: white;
  border-radius: 4px;
  box-shadow: $box-shadow;
  border: 1px solid $light-color;
}

.mat-input-and-select .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}

.mat-input-and-select .mat-mdc-select {
  display: inherit;
}

.mat-input-and-select .mdc-text-field--disabled {
  background-color: #fafafa;
}

.mat-input-and-select .mat-mdc-select-arrow {
  opacity: 0;
}

.custom-textarea {
  padding: 1em;
  background: white;
  border: 1px solid $light-color;
  border-radius: $default-border-radius;
  font-family: Monoserrat, sans-serif;
  font-size: $default-font-size;
  line-height: $default-line-height;
  color: $dark-color;
}
