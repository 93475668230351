/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.

// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight templates Angular Material theming.

@use "@angular/material" as mat;

// Define a custom typography config
$my-typography: mat.define-typography-config(
  $font-family: '"Monoserrat", sans-serif;',
);

$custom-dark-primary-text: rgb(0 0 0 / 87%);
$custom-light-primary-text: rgb(255 255 255 / 87%);

$custom-green-palette: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #17ab6f,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $custom-light-primary-text,
    100: $custom-light-primary-text,
    200: $custom-light-primary-text,
    300: $custom-light-primary-text,
    400: $custom-light-primary-text,
    500: $custom-light-primary-text,
    600: $custom-light-primary-text,
    700: $custom-light-primary-text,
    800: $custom-light-primary-text,
    900: $custom-light-primary-text,
    A100: $custom-light-primary-text,
    A200: $custom-light-primary-text,
    A400: $custom-light-primary-text,
    A700: $custom-light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.define-palette(mat.$green-palette, 700, 500, 900);
$app-primary-dark: mat.define-palette(mat.$green-palette, 700, 500, 900);

// stylelint-disable-next-line value-keyword-case
$app-accent: mat.define-palette(mat.$blue-palette, 700, 500, 900);
$app-accent-dark: mat.define-palette(mat.$blue-palette, 700, 500, 900);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);
$app-warn-dark: mat.define-palette(mat.$amber-palette);

// Create the theme object (a Sass map containing all of the palettes).
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $my-typography,
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $app-primary-dark,
      accent: $app-accent-dark,
      warn: $app-warn-dark,
    ),
    typography: $my-typography,
  )
);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

$navHeaderBackgroundColor: mat.get-color-from-palette($app-primary-dark, default, 0.5);
$toolbar-bottom-border-height: 6px;
$nav-bottom-border-height: 4px;
$toolbar-height: 48px;
$toolbar-line-height: 48px;
$second-toolbar-height: 56px;
$toolbar-min-height: calc($toolbar-line-height + $second-toolbar-height + $toolbar-bottom-border-height);
$main-toolbar-height: calc($toolbar-height + $toolbar-bottom-border-height);
$left-sidebar-width: 250px;
$light-color: #e9e9e9;
$dark-color: #313131;
$box-shadow: 0 4px 8px 0 #728e9d0f;
$default-line-height: 1.5rem;
$default-font-size: 1rem;
$default-border-radius: 4px;
$accent-blue: #1976d2;
$table-header-font-weight: 700;
$rejected-and-failed-color: #e0201a;
$pending-grey-color: #aaa;
$cancelled-color: $dark-color;
$running-color: #256ae5;
$pending-yellow-color: #fca106;
$approved-and-succeeded-color: #17ab6f;
